@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Extrathin.otf")
    format("opentype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Thin.otf")
    format("opentype");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Light.otf")
    format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Regular.otf")
    format("opentype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Semibold.otf")
    format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Bold.otf")
    format("opentype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Extrabold.otf")
    format("opentype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Clean";
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/font-clean/Averta-Black.otf")
    format("opentype");
  font-display: swap;
  font-weight: 900;
}

/* EDITOTIAL */

@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Ultralight.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Ultralight.woff")
    format("woff");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Thin.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Thin.woff")
    format("woff");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Thin.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Thin.woff")
    format("woff");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Regular.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Regular.woff")
    format("woff");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Bold.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Bold.woff")
    format("woff");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Bold.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Bold.woff")
    format("woff");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Bold.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Bold.woff")
    format("woff");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Editorial";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Heavy.woff2")
    format("woff2");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/branding/font/Editorial/PPEditorialNew-Heavy.woff")
    format("woff");
  font-style: normal;
  font-weight: 800;
}

b {
  font-weight: 500;
}

body,
html {
  height: 100%;
  font-family: "Clean", "Sans-Serif" !important;
  font-display: swap;
  --bs-gutter-x: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: clip;

  height: fit-content;
}

.wmde-markdown {
  font-family: "Clean", "Sans-Serif";
  font-display: swap;
}

.wmde-markdown h1 {
  border-bottom: 0rem !important;
  font-family: "Editorial";
}

.wmde-markdown h2 {
  font-family: "Editorial";
  margin-top: 40px;
}

.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  /* letter-spacing: 0.025rem; */
  margin-top: 0;
}

/* Highligh mark overwrite */
.highlight-mark {
  background-color: transparent;
  color: #000;
  font-weight: 400;
  padding: 0;
}

/* default button */

button {
  color: none;
  background-color: white;
  border-color: white;
  border-style: none;
  border-width: 1px;
  vertical-align: middle;
}

/* Nav-Pills */

.carousel-inner {
  border-radius: 5px !important;
}

.dropdown-item:active {
  background-color: var(--primary-purple) !important;
}

.dropdown-item::after {
  background-color: var(--primary-purple) !important;
}

/* Accordion */
.accordion-button:not(.collapsed) {
  background-color: white !important;
  color: var(--primary-purple) !important;
  border-color: white !important;
  border-radius: 15px;
}

.accordion-button:focus {
  border-color: white !important;
  border-radius: 15px !important;
}

/* Forms default overwrite */

.form-control {
  background-color: #fefefe !important;
  border: 1px solid var(--primary-border) !important;
  font-size: 100% !important;
  border-radius: 3px !important;
  padding: 11px 16px !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem #63234046 !important;
}

::selection {
  background-color: #6323404d;
  color: var(--primary-text);
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem #63234046 !important;
}

.form-select {
  background-color: #fefefe !important;
  border: 1px solid #d2d6dc !important;
  color: #212529 !important;

  font-size: 100% !important;
  padding: 11px 16px !important;
}

.form-select:focus {
  box-shadow: 0 0 0 0.25rem #63234046 !important;
}

.form-label {
  font-weight: 500;
  line-height: 1.8;
  color: var(--text-main);
  display: block;
  font-size: 14px;
}

.form-check-input:checked {
  background-color: var(--text-main) !important;
  border-color: var(--text-main) !important;
}

/* End */

/* Modals */

.modal-fullscreen {
  width: 100%;
}
body.modal-open {
  overflow-y: hidden;
  width: 100vw;
}

/* Mapbox */
.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right > .mapboxgl-ctrl-attrib.mapboxgl-compact:after {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

/* react-typeout */

.react-typeout-text {
  margin-bottom: 0rem;
  color: var(--text-body);
  font-weight: 400;
}
/* 
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: transparent;
} */

:root {
  --primary-green-700: #163116;
  --primary-green-600: #2c612c;
  --primary-green-500: #429242;
  --primary-green-400: #63b863;
  --primary-green-300: #8aca8a;
  --primary-green-200: #b1dbb1;
  --primary-green-100: #e8f5e8;

  --primary-dark-green-700: #031109;
  --primary-dark-green-600: #052313;
  --primary-dark-green-500: #08341c;
  --primary-dark-green-400: #0a4525;
  --primary-dark-green-300: #18a357;
  --primary-dark-green-200: #43e48c;
  --primary-dark-green-100: #a1f1c6;

  --primary-purple: #632340;
  --primary-pink: #ffd6e4;
  --tetradic-blue: #223f62;
  --tetradic-yellow: #624522;
  --tetradic-green: #226245;

  --primary-red-700: #311616;
  --primary-red-600: #612c2c;
  --primary-red-500: #924242;
  --primary-red-400: #b86363;
  --primary-red-300: #ca8a8a;
  --primary-red-200: #dbb1b1;
  --primary-red-100: #edd8d8;

  --primary-blue-700: #162431;
  --primary-blue-600: #2c4761;
  --primary-blue-500: #426b92;
  --primary-blue-400: #638eb8;
  --primary-blue-300: #8aaaca;
  --primary-blue-200: #b1c6db;
  --primary-blue-100: #d8e3ed;

  --primary-bg: #f5f5f5;
  --primary-bg-light: #fafafa;
  --primary-bg-dark: #d6d6d6;
  --secondary-gray: #e8edf2;
  --secondary-gray-darker: #d5dee7;

  --text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);

  --primary-border: #e4e5e7;
  --primary-border-light: rgb(242 244 249);
  --box-shadow-1: rgba(47, 48, 97, 0.12) 0px 6px 12px;

  --text-main: #222325;
  --text-body: #62646a;
  --text-mute: #95979d;
  --theme-bg: #f5f2ee;

  --accent-red: #c96868;
  --accent-yellow: #fadfa1;
  --accent-beige: #fff4ea;
  --accent-blue: #7eacb5;
  --accent-purple: #bb9ab1;
  --accent-green: #a5b68d;

  --space-mid: 1rem;
  --max-width: 1140px;
}
