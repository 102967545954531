.wrapper {
  position: sticky;
  bottom: 0;
  z-index: 50000;
  width: 100%;
}

.alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  position: fixed;
  height: fit-content;
  bottom: 30px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  z-index: 500;
  width: fit-content;
  height: fit-content;
}

.alert.danger {
  background: var(--accent-red);
  color: var(--text-main);
}

.alert.success {
  background: var(--accent-green);
  color: var(--text-main);
}

.content {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  padding-bottom: 5px;

  gap: 7px;
}

.msg {
  font-size: 15px;
  font-weight: 500;
}

.progressBar {
  z-index: 100;
  height: 5px;
  width: 100%;
}

.progress {
  z-index: 100;
  width: 100%;
  height: 100%;
  animation: roundtime 7s 1;
  transform-origin: left center;
}
.iconMessage {
  width: 17px;
  height: 17px;
}

.progressBar.success {
  background: #e5f4d1;
  /* box shadow inner */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  .progress {
    background: #496427;
  }
}

.progressBar.danger {
  background: #fbe8e8;
  /* box shadow inner */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

  .progress {
    background: #7d1c1c;
  }
}

.closeIconWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -5px;
  right: -5px;
  width: 20px;
  padding: 0;
  height: 20px;
  border-radius: 100%;
}

.closeIconWrapper.success {
  background: #e5f4d1;
  color: #496427;
}

.closeIconWrapper:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.closeIconWrapper:active {
  transform: scale(0.98);
}

.closeIconWrapper.danger {
  background: #fbe8e8;
  color: #7d1c1c;
}

.closeIcon {
  width: 80%;
  height: 80%;
}

@keyframes roundtime {
  to {
    /* More performant than animating `width` */
    transform: scaleX(0);
  }
}

@media (max-width: 600px) {
  .alert {
    bottom: 20px;
    width: fit-content;
  }

  .msg {
    font-size: 14px;
  }
}
